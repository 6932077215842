import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import {PhoneIcon} from '@chakra-ui/icons';
import {Button} from '@chakra-ui/button';
import {ChakraProvider, Link, Text} from '@chakra-ui/react';
import theme from "./Theme";

// NOTE: pageTitle is rendered as plain text, so it's safe to update it. See [id="pageTitle"] below
// NOTE: pageSubTitleHtml is a string of HTML, so be careful when updating it. See [id="pageSubTitleHtml"] below
const defaultRenderData = {
  // This is used as defaults if not overridden in renderDataForLeadSource
  facebookPixelId: '1115349066149534',
  pageTitle: 'Home Care Provider',
  pageSubTitleHtml:
    '- Love us, or we will refund your first three month of fees!\n' +
    '<br/><br/>\n' +
    '- <b>Book a free no-obligation phone call</b> with one of our concierge team to ' +
    'find out how Aunty Grace can tailor a Home Care solution specifically to meet your needs.',
  phoneNumberHref: 'tel:1300008801',
  phoneNumber: '1300 008 801'
};
const socialRenderData = {
  pageTitle: 'Book a free consultation here',
  pageSubTitleHtml:
    'Explore how Aunty Grace’s personalised home care can make a difference for you and your family. ' +
    'Book your no-obligation phone call today.',
  phoneNumberHref: 'tel:1300 010 108',
  phoneNumber: '1300 010 108'
};
// See src/App.js:App() for the routes that map to these keys (via the functions in export at the bottom of this file)
const renderDataForLeadSource = {
  'landing_page_unknown': socialRenderData,
  'landing_page_social_info': socialRenderData,
  'landing_page_social_media': socialRenderData,
  'landing_page_home_care_packages': {pageTitle: 'Home Care Package Provider'},
  'landing_page_home_care_packages_level_1': {pageTitle: 'Level 1 Home Care Package Provider'},
  'landing_page_home_care_packages_level_2': {pageTitle: 'Level 2 Home Care Package Provider'},
  'landing_page_home_care_packages_level_3': {pageTitle: 'Level 3 Home Care Package Provider'},
  'landing_page_home_care_packages_level_4': {pageTitle: 'Level 4 Home Care Package Provider'},
  'landing_page_home_care_services': {pageTitle: 'Home Care Service Provider'},
  'landing_page_home_care': {pageTitle: 'Home Care Provider'},
  'landing_page_home_care_melbourne': {pageTitle: 'Home Care In Melbourne'},
};

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1380,
      settings: {
        slidesToShow: 3.5, // when screen width is less than 1380px
      },
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 3.2, // when screen width is less than 1220px
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 3, // when screen width is less than 1120px
      },
    },
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 2.5, // when screen width is less than 1080px
      },
    },
    {
      breakpoint: 924,
      settings: {
        slidesToShow: 2.2, // when screen width is less than 924px
      },
    },
    {
      breakpoint: 830,
      settings: {
        slidesToShow: 2, // when screen width is less than 830px
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1.5, // when screen width is less than 780px
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1.5, // when screen width is less than 640px
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1.2, // when screen width is less than 520px
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.1, // when screen width is less than 400px
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1, // when screen width is less than 380px
      },
    },
  ],
};

function LandingBase(leadSource) {
  console.assert(leadSource in renderDataForLeadSource, leadSource, renderDataForLeadSource);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    customerAdvisedLeadStage: '',
    leadSource: leadSource,
  });
  const [renderData] = useState({...defaultRenderData, ...renderDataForLeadSource[leadSource]});
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    window.document.title = `${renderData.pageTitle} - Aunty Grace`;
  }, [renderData.pageTitle]);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const getGaClientId = () => {
    try {
      // noinspection JSUnresolvedReference
      return window.gaGlobal && window.gaGlobal["vid"];
    } catch (e) {
      console.error('Error fetching GA Client ID:', e);
      return null;
    }
  };

  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  const validateForm = () => {
    if (!formData.firstName || !formData.lastName || !formData.phoneNumber || !formData.customerAdvisedLeadStage) {
      setError('Please fill in all required fields.');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const gclid = getQueryParam('gclid');
    let viewPage = gclid ? `gclid${gclid}` : '';
    // Append additional information to viewPage as needed

    const analyticsData = {
      gaClientId: getGaClientId(),
      referrerUrl: window.document.referrer,
      documentUrl: window.document.URL,
      viewPage: [viewPage]
    };

    const dataToSend = {
      ...formData,
      analyticsData: analyticsData
    };

    try {
      const response = await axios.post('/api/submit-form', dataToSend);
      // Send event to dataLayer
      // noinspection JSUnresolvedReference
      window && window.dataLayer && window.dataLayer.push({'event': 'social_landing_page_form_submit'});
      console.log(response);
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
      setError('There was an error submitting the form, please try again.');
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <div className="landing-page-for">
        <div className="div">
          <div className="header-and-form">
            <div className="max-width-wrapper">
              <div className="header-contact-us">
                <img className="ag-logo" alt="Aunty Grace Logo" width="450" height="184" src="/img/ag-logo.svg"/>
              </div>
              <div className="title-description">
                <p id="pageTitle" className="title">{renderData.pageTitle}</p>
                <p id="pageSubTitleHtml" className="description-5"
                   dangerouslySetInnerHTML={{__html: renderData.pageSubTitleHtml}}></p>
              </div>
              <form onSubmit={handleSubmit}>
                {isSubmitted ? (
                  <p className="formSuccessMessage"><h2>Thank you for booking your consultation, we will be in touch
                    soon.</h2>
                  </p>
                ) : (<>
                    <div className="form">
                      <div className="overlap-wrapper">
                        <input className="input-field" id="firstName" name="firstName" placeholder="First name*"
                               value={formData.firstName} onChange={handleChange}/>
                      </div>
                      <div className="overlap-wrapper">
                        <input className="input-field" id="lastName" name="lastName" placeholder="Last name*"
                               value={formData.lastName} onChange={handleChange}/>
                      </div>
                      <div className="overlap-wrapper">
                        <input className="input-field" id="email" name="email" placeholder="Email"
                               value={formData.email} onChange={handleChange}/>
                      </div>
                      <div className="overlap-wrapper">
                        <input className="input-field" id="phoneNumber" name="phoneNumber" placeholder="Phone number*"
                               value={formData.phoneNumber} onChange={handleChange}/>
                      </div>
                      <div className="overlap-wrapper">
                        <select className="input-field" id="customerAdvisedLeadStage" name="customerAdvisedLeadStage"
                                value={formData.customerAdvisedLeadStage} onChange={handleChange}>
                          <option value="" disabled>Which describes you best?*</option>
                          <option value="not_mac_registered">We're just getting started</option>
                          <option value="approved">We're waiting for a package</option>
                          <option value="assigned">We have a home care package</option>
                          <option value="no_end_date_provided">We're looking to switch providers</option>
                        </select>
                      </div>
                    </div>
                    <div className="submit">
                      <p className="consent">By clicking "Submit" I accept the <Link
                        href="https://www.auntygrace.com.au/privacy-policy/" target="_blank" rel="noreferrer">Privacy
                        Policy</Link> and I acknowledge that I can opt out from contact at any time.</p>
                      <Button variant="blackButton" type="submit">Submit</Button>
                      {error && <p style={{color: 'red'}}>{error}</p>}
                    </div>
                  </>
                )}
              </form>
            </div>
            <img className="header-cloud" alt="Header cloud" src="/img/header-cloud.png"/>
          </div>
          <div className="why-auntie-grace">
            <div className="max-width-wrapper">
              <div className="why-auntie-grace-2">
                <div className="div-2">
                  <p className="div-5">
                    <span className="text-wrapper-8">Why </span>
                    <span className="text-wrapper-5">Aunty Grace? </span>
                  </p>
                </div>
                <div className="div-wrapper">
                  <div className="text-wrapper-9">
                    Be part of the Aunty Grace difference where your unique care plan comes to life, crafted by our
                    diverse team of caring experts, at an affordable price and 3-month money back guarantee
                  </div>
                </div>
                <div className="info">
                  <div className="icon">
                    <img className="trust" alt="Trust" src="/img/icons8-trust-1.png"/>
                  </div>
                  <div className="content">
                    <p className="div-5">
                      <span className="text-wrapper-4">Personalised</span>
                      <span className="span"> Care</span>
                    </p>
                    <p className="description">
                      We understand every journey is unique. Our Care Managers are here to listen, understand, and
                      tailor a care plan that truly reflects your individual needs and wishes
                    </p>
                  </div>
                </div>
                <div className="info">
                  <div className="icon">
                    <img className="icons-doctor-female" alt="Doctor female" src="/img/icons8-doctor-female-1.png"/>
                  </div>
                  <div className="content">
                    <p className="div-5">
                      <span className="text-wrapper-4">Comprehensive</span>
                      <span className="span"> Services</span>
                    </p>
                    <p className="description">
                      At Aunty Grace, we provide a large range of services that support and enable you to lead your
                      best life. We understand that having help doesn’t have to mean losing your independence.
                    </p>
                  </div>
                </div>
                <div className="info">
                  <div className="icon">
                    <img className="group" alt="Group" src="/img/group-14763.png"/>
                  </div>
                  <div className="content">
                    <p className="div-5">
                      <span className="text-wrapper-4">Affordable</span>
                      <span className="text-wrapper-5"> Care</span>
                    </p>
                    <p className="description">
                      As an approved provider of the Australian Government's Home Care Packages, Aunty Grace offers
                      affordable care options tailored to your needs. We also offer a 3-month money-back guarantee so
                      you can choose us with confidence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-services">
            <div className="max-width-wrapper">
              <div className="div-4">
                <div className="section-title">
                  <p className="div-5">
                    <span className="span">Our </span>
                    <span className="text-wrapper-4">Services</span>
                  </p>
                </div>
                <div className="cards-scroll">
                  <Slider {...sliderSettings}>
                    <div>
                      <div className="card">
                        <div className="rectangle-card">
                          <div className="text-content">
                            <div className="icon-and-title">
                              <img className="img-2" alt="Customer" src="/img/icons8-customer-1.svg"/>
                              <div className="service-name">Domestic Assistance</div>
                            </div>
                            <div className="description">
                              We can help with those life tasks that just need to get done, such as:
                              <ul>
                                <li>Paying the bills</li>
                                <li>Cleaning</li>
                                <li>Home maintenance and modifications; and</li>
                                <li>Gardening.</li>
                              </ul>
                              Take away the stress of managing these tasks and spend more time doing the things that
                              bring you joy.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="rectangle-card">
                          <div className="text-content">
                            <div className="icon-and-title">
                              <img className="img-2" alt="Trust" src="/img/icons8-trust-1-1.svg"/>
                              <div className="service-name">Personal Care</div>
                            </div>
                            <div className="description">
                              Our personal care services can help keep you looking and feeling your best. Increase
                              your confidence in how you are caring for yourself with the following services:
                              <ul>
                                <li>Showering, bathing, and toileting</li>
                                <li>Grooming</li>
                                <li>Food and medication; and</li>
                                <li>Staying active.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="card">
                        <div className="rectangle-card">
                          <div className="text-content">
                            <div className="icon-and-title">
                              <img className="img-2" alt="Trust" src="/img/icons8-trust-1-1.svg"/>
                              <div className="service-name">Meal Solutions</div>
                            </div>
                            <div className="description">
                              Access to locally produced meals made with love by a selection of approved providers
                              <ul>
                                <li>Select your own meals</li>
                                <li>Delivered to your door</li>
                                <li>Pay only 30% of the meal cost out of pocket (covering ingredients costs)</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="rectangle-card">
                          <div className="text-content">
                            <div className="icon-and-title">
                              <img className="img-2" alt="Head with" src="/img/icons8-head-with-brain-1.svg"/>
                              <div className="service-name">Peace of Mind</div>
                            </div>
                            <div className="description">
                              Services to monitor your health, remove any worry and return peace of mind to you and
                              the whole family, including:
                              <ul>
                                <li>Wellness check-ins</li>
                                <li>Remote care monitoring</li>
                                <li>Equipment and assistive technology; and</li>
                                <li>Transport.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="rectangle-card">
                          <div className="text-content">
                            <div className="icon-and-title">
                              <img className="img-2" alt="Users" src="/img/users.svg"/>
                              <div className="service-name">Social</div>
                            </div>
                            <p className="description">
                              Staying connected and involved with your community is vital to maintaining complete
                              wellness. We can help with the little things right through to the events that matter.
                              Anything from face time for the kids overseas, to a community event of like-minded people.
                              You can also meet and talk with others in the Aunty Grace community via our community
                              portal and events.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="rectangle-card">
                          <div className="text-content">
                            <div className="icon-and-title">
                              <img className="doctor-female" alt="Doctor female"
                                   src="/img/icons8-doctor-female-1-1.png"/>
                              <div className="service-name-2">Clinical Services</div>
                            </div>
                            <div className="description">
                              Our health professionals can support you to proactively manage or maintain your health,
                              pain, and chronic conditions. These services include:
                              <ul>
                                <li>Nursing</li>
                                <li>Podiatry</li>
                                <li>Occupational therapy</li>
                                <li>Physiotherapy; and</li>
                                <li>Specialised supports e.g. continence, dementia, vision impairment, other medical
                                  conditions.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="max-width-wrapper">
              <div className="footer-content">
                <div className="flex-expander">&nbsp;</div>
                <p className="want-more">
                  Want more information? <br/>
                  Get in touch with us now.
                </p>
                <div className="flex-expander">&nbsp;</div>
                <div className="contact-us">
                  <Link href={renderData.phoneNumberHref}>
                    <Button variant="blackButton">
                      <PhoneIcon height={22} width={22} mr={2}/>
                      <Text>{renderData.phoneNumber}</Text>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <svg viewBox="0 0 148 68" focusable="false" className="chakra-icon footer-cloud"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M148 68V5.854a8.58 8.58 0 00-3.012-1.927c-3.954-1.494-8.872-3.354-14.447-3.82-7.147-.61-14.025 1.416-18.871 5.519-4.316 3.648-6.485 8.328-8.226 12.087-1.079 2.321-2.295 4.958-3.352 5.591-.536.322-2.305.622-5.558-.288-1.194-.333-2.454-.767-3.784-1.227-2.996-1.028-6.391-2.2-10.269-2.649-13.176-1.51-26.834 6.524-31.774 18.728-1.024 2.526-1.626 5.03-2.152 7.24-.608 2.537-1.238 5.158-2.196 6.135-.756.778-3.072 1.566-7.3 1.466-1.106-.028-2.218-.089-3.33-.15-4.414-.227-9.414-.522-14.72.955C10.526 55.868 4.617 61.515 0 68h148z"></path>
            </svg>
            <div className="footer-logo">
              <svg viewBox="200 215 200 205" focusable="false" className="chakra-icon svg"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M104.55 339.47l-4.26 9.48c-.32.64-.64 1.21-2.23 1.21-1.14 0-1.65-.7-1.65-1.46 0-.25.19-.64.19-.64l20.29-44.15c.57-1.21.95-1.85 2.48-1.85 1.4 0 2.04.83 2.42 1.72l20.36 44.28s.19.51.19.89 0 1.21-1.65 1.21c-1.34 0-1.97-.64-2.23-1.21l-4.26-9.48h-29.65zm1.59-3.5h26.47l-13.23-29.33-13.24 29.33zM177.9 310.65c0-.57.25-1.02 1.65-1.02s1.65.57 1.65 1.02v26.4c0 7.19-6.68 13.68-17.24 13.68-10.88 0-17.11-6.49-17.11-13.68v-26.4c0-.51.45-1.02 1.78-1.02 1.46 0 1.84.45 1.84 1.02v26.27c0 5.73 4.45 10.56 13.49 10.56 8.84 0 13.93-4.83 13.93-10.56v-26.27zM196.22 310.33L223 343.86v-33.21c0-.45.45-1.02 1.59-1.02s1.59.57 1.59 1.02v38.55c0 .64-.51.95-1.34.95-.95 0-1.34-.51-1.72-1.02l-27.29-33.59v33.53c0 .51-.38 1.08-1.59 1.08s-1.59-.51-1.59-1.08v-38.36c0-.51.51-1.08 1.78-1.08.77 0 1.47.25 1.79.7zM250.8 312.87v36.2c0 .57-.45 1.08-1.84 1.08-1.4 0-1.78-.51-1.78-1.08v-36.2H234.9s-1.08-.25-1.08-1.65c0-1.34 1.08-1.59 1.08-1.59h28.12s1.08.25 1.08 1.59c0 1.4-1.08 1.65-1.08 1.65H250.8zM269.19 311.86s-.32-.57-.32-1.08c0-.57.57-1.14 1.59-1.14.95 0 1.72.38 2.29 1.27l13.11 19.47 13.49-20.23s.51-.51 1.46-.51c.89 0 1.78.45 1.78 1.21 0 .25-.32.64-.32.64l-14.76 22.33v15.27c0 .51-.38 1.08-1.84 1.08-1.34 0-1.78-.57-1.78-1.08V333.8l-14.7-21.94z"></path>
                <g>
                  <path
                    d="M328.58 288.36c0 1.12-.92 1.43-1.43 1.43-.92 0-5.72-7.05-17.16-7.05-7.36 0-14.51 4.6-14.51 12.36 0 11.34 12.87 18.49 27.79 20.64 11.95-20.94 37.29-38.51 56.29-38.51 7.15 0 11.13 3.68 11.13 9.09 0 17.98-31.67 33.3-57.82 33.3-2.45 0-4.9-.1-7.36-.41-3.37 6.33-5.41 12.87-5.41 19.1 0 11.14 6.64 17.98 17.98 17.98 5.11 0 10.32-1.63 15.02-4.19 5.52-13.69 10.42-24.62 16.96-24.62 2.55 0 4.5 1.74 4.5 4.29 0 5.72-7.76 15.73-18.29 21.96l-4.6 11.85c7.76.92 16.14 4.29 16.14 12.57 0 1.74-.21 2.25-.92 2.25s-.92-.61-1.02-1.33c-1.12-7.76-8.99-10.11-15.43-10.62-8.58 20.53-20.64 41.78-41.88 41.78-7.46 0-13.28-5.11-13.28-12.46 0-17.98 29.63-32.38 52.1-32.38h.41l3.68-9.19c-4.39 1.94-8.99 3.17-13.69 3.17-12.67 0-21.35-7.36-21.35-20.64 0-6.64 1.94-13.49 5.21-20.02-15.83-2.55-29.73-10.62-29.73-23.8 0-9.91 8.68-15.53 17.88-15.53 11.23-.01 18.79 7.96 18.79 8.98zm17.98 79.99c-19.92.21-47.71 13.18-47.71 29.52 0 5.52 3.98 8.89 10.01 8.89 18.49 0 29.42-19 37.7-38.41zm-19.41-52.1c2.04.2 4.19.2 6.23.2 22.07 0 53.74-13.69 53.74-30.54 0-3.47-3.17-5.52-7.46-5.52-17.07 0-40.77 16.55-52.51 35.86zM358 349.04c7.56-5.41 12.97-12.46 12.97-17.06 0-.72-.41-1.12-1.02-1.12-4.19 0-7.87 7.66-11.95 18.18z"></path>
                  <path
                    d="M397.02 321.46c1.12.51 1.84 1.12 1.84 2.15 0 4.39-8.89 10.73-8.89 16.65 0 1.84 1.12 2.66 2.76 2.66 4.8 0 10.73-4.8 16.04-10.73 0 0 .51-.61 1.02-.61s1.12.61 1.12 1.12c0 .41-.51 1.02-.51 1.02-5.72 6.23-12.87 11.85-18.29 11.85-3.07 0-5.52-1.94-5.52-5.11 0-6.33 8.27-12.46 8.27-16.04 0-.51-.51-.92-1.23-1.43-2.15-1.53-3.37-2.35-3.98-2.35-.41 0-.71.31-1.33 1.12-1.53 2.14-5.82 7.66-9.6 11.95 0 0-.51.51-1.02.51-.61 0-1.12-.61-1.12-1.02 0-.41.51-1.02.51-1.02 3.47-3.88 8.99-11.54 10.62-14.71 1.23-2.45 2.96-8.99 5.52-8.99 1.02 0 2.25 1.33 2.25 2.15 0 1.53-4.39 5.21-4.39 6.74 0 .61 3.89 2.96 5.93 4.09z"></path>
                  <path
                    d="M427.46 334.94c-3.98 4.6-11.54 11.54-16.75 11.54-3.07 0-5.31-1.84-5.31-5.31 0-10.01 14.61-23.8 25.85-23.8 5.11 0 7.15 2.66 7.15 5.21 0 2.04-1.43 3.47-2.15 3.47a.81.81 0 01-.82-.82c0-.71.31-1.12.31-2.14 0-.92-.51-2.96-3.58-2.96-10.83 0-23.29 12.97-23.29 21.04 0 1.43.71 2.35 2.14 2.35 4.29 0 11.14-6.33 14.51-10.42 2.76-3.27 4.5-6.23 5.62-6.23.82 0 1.63.82 1.63 1.63 0 .71-2.04 3.17-2.04 6.64 0 3.68 1.84 5.21 4.09 5.21 2.96 0 6.74-2.25 12.05-8.17 0 0 .51-.61.92-.61.61 0 1.23.61 1.23 1.12 0 .41-.51 1.02-.51 1.02-5.72 6.23-10.42 9.3-14.2 9.3-3.99 0-6.85-3.47-6.85-8.07z"></path>
                  <path
                    d="M454.53 346.08c-5.82 0-9.19-3.58-9.19-8.17 0-9.6 11.75-20.23 19.61-20.23 3.27 0 5.62 1.74 5.62 4.39 0 2.45-2.66 4.6-3.37 4.6s-1.23-.61-1.23-1.23c0-.51 1.74-1.63 1.74-3.37 0-.82-.51-1.74-2.04-1.74-6.64 0-16.75 9.19-16.75 17.67 0 3.47 2.35 5.42 6.03 5.42 10.42 0 18.29-8.58 20.74-11.24 0 0 .51-.61 1.02-.61s1.12.61 1.12 1.12c0 .41-.51 1.02-.51 1.02-2.46 2.66-11.96 12.37-22.79 12.37z"></path>
                  <path
                    d="M484.15 346.69c-5.52 0-10.01-3.27-10.01-9.09 0-9.5 12.77-19.82 20.64-19.82 3.17 0 5.31 1.84 5.31 4.39 0 6.13-13.69 11.75-22.17 13.89-.2.61-.2 1.23-.2 1.84 0 3.58 2.66 5.82 6.44 5.82 12.26 0 21.66-11.54 21.66-11.54s.51-.61 1.02-.61c.61 0 1.12.61 1.12 1.12 0 .41-.51 1.02-.51 1.02s-11.45 12.98-23.3 12.98zm-5.31-13.59c7.76-2.04 18.18-6.95 18.18-11.24 0-.31-.31-1.23-1.84-1.23-5.61.01-13.38 6.14-16.34 12.47z"></path>
                </g>
                <path
                  d="M459.38 237.57c-2.82-1.81-6.93-2.53-11.2-1.67-.05-.51-.13-1.01-.26-1.48-.72-2.62-2.37-4.54-4.16-5.97-2.89-2.3-6.44-3.57-9.86-2.42s-6.56 5.11-6.87 9.72c-.34 5.09 2.9 9.46 6.65 10.25.27.06.55.09.82.12-9.44 15.42 4.67 23.89 5.54 23.53.56-.23.99-.53 1.37-.86 3.87 2.45 8.52 7.61 7.37 10.55l1.65.65c1.78-4.51-4.36-10.27-7.89-12.59l.03-.06c.41-.79-2.5-3.76-3.28-4.18-1.96-1.05-2.15-4.58-.84-6.41 1.55-2.17 4.65 1.86 4.65 1.86s2.84-1.85 3.46-4.02c.62-2.17.64-10.04 9.06-1.61 1.76 1.76 4.63 2.91 6.96 3.3.11.6.17 1.12.16 1.52-.11 3.6-1.46 6.67-3.72 8.42-.22.17-.46.34-.69.5-.78.55-1.66 1.17-2.19 2.14-.47.86-.56 1.81-.6 2.72-.12 3.04.3 6.05 1.24 8.94l1.69-.55c-.87-2.69-1.26-5.5-1.15-8.33.03-.8.11-1.43.38-1.94.33-.61.97-1.06 1.65-1.54.26-.18.51-.36.76-.55 2.68-2.08 4.28-5.64 4.4-9.77.01-.4-.03-.89-.12-1.43.96-.03 1.67-.25 1.88-.72 1.56-3.52 2.46-12.13-6.89-18.12z"></path>
                <path fill="#fff68D"
                      d="M475.02 301.1c-2.16-5.46-4.89-11.46-5.93-13.78-.99-2.22-2.64-4.7-4.76-5.99-.17-.1-.33-.19-.49-.26-1.5-1.8-3.78-2.98-7.3-2.34-1.64.3 1.73 13.5-2.85 12.63-4.63-.88-.84-13.48-3.47-13.87-3.01-.45-7.67-.12-10.65.5-7.23 1.51-10.75 5.2-14.37 11.64-.2.36-4.78 8.19-6.52 11.47h56.34z"></path>
              </svg>
            </div>
          </footer>
        </div>
      </div>
    </ChakraProvider>
  );
}

function LandingUnknown() {
  return LandingBase('landing_page_unknown');
}

function LandingHomeCarePackages() {
  return LandingBase('landing_page_home_care_packages');
}

function LandingHomeCarePackagesLevel1() {
  return LandingBase('landing_page_home_care_packages_level_1');
}


function LandingHomeCarePackagesLevel2() {
  return LandingBase('landing_page_home_care_packages_level_2');
}

function LandingHomeCarePackagesLevel3() {
  return LandingBase('landing_page_home_care_packages_level_3');
}

function LandingHomeCarePackagesLevel4() {
  return LandingBase('landing_page_home_care_packages_level_4');
}

function LandingSocialInfo() {
  return LandingBase('landing_page_social_info');
}

function LandingSocialMedia() {
  return LandingBase('landing_page_social_media');
}

function LandingHomeCareServices() {
  return LandingBase('landing_page_home_care_services');
}


function LandingHomeCare() {
  return LandingBase('landing_page_home_care');
}

function LandingHomeCareMelbourne() {
  return LandingBase('landing_page_home_care_melbourne');
}

export {
  LandingUnknown,
  LandingHomeCarePackages,
  LandingHomeCarePackagesLevel1,
  LandingHomeCarePackagesLevel2,
  LandingHomeCarePackagesLevel3,
  LandingHomeCarePackagesLevel4,
  LandingSocialInfo,
  LandingSocialMedia,
  LandingHomeCareServices,
  LandingHomeCare,
  LandingHomeCareMelbourne,
};
