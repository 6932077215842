import {Outlet, BrowserRouter, Routes, Route} from "react-router-dom";
import * as E from "./Landing";

const Layout = () => {
    return (
      <Outlet/>
    );
}

function App() {
    return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout/>}>
                  <Route index element={<E.LandingSocialMedia/>}/>
                  <Route path="social" element={<E.LandingSocialInfo/>}/>
                  <Route path="home-care-packages" element={<E.LandingHomeCarePackages/>}/>
                  <Route path="home-care-packages-level-1" element={<E.LandingHomeCarePackagesLevel1/>}/>
                  <Route path="home-care-packages-level-2" element={<E.LandingHomeCarePackagesLevel2/>}/>
                  <Route path="home-care-packages-level-3" element={<E.LandingHomeCarePackagesLevel3/>}/>
                  <Route path="home-care-packages-level-4" element={<E.LandingHomeCarePackagesLevel4/>}/>
                  <Route path="home-care-services" element={<E.LandingHomeCareServices/>}/>
                  <Route path="home-care" element={<E.LandingHomeCare/>}/>
                  <Route path="home-care-melbourne" element={<E.LandingHomeCareMelbourne/>}/>
                  <Route path="*" element={<E.LandingUnknown/>}/>
              </Route>
          </Routes>
      </BrowserRouter>
    );
}

export default App;
