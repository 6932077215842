import '@fontsource/nunito-sans';
// import {Button} from "@chakra-ui/button";
import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'

const global = {
  p: {
    fontSize: { base: 'md', md: 'xl' },
  },
  ul: {
    fontSize: { base: 'md', md: 'xl' },
    ml: { base: 6, md: 8 },
  },
  h2: {
    fontSize: { base: '1.6rem', md: '1.75rem' },
  },
  h3: {
    textTransform: 'capitalize',
  },
}

const Text = {
  baseStyle: {
    fontSize: { base: 'md', md: 'xl' },
  },
  sizes: {
    sm: {
      fontSize: 'md',
    },
    md: {
      fontSize: 'xl',
    },
  },
}

const AccordionItem = {
  baseStyle: {
    borderWidth: 0,
    borderBottom: 0,
  },
}

const TextArea = {
  baseStyle: {
    color: 'red',
    _placeholder: { color: 'gray.25' },
    variants: {
      outline: {
        borderColor: 'gray.25',
      },
    },
  },
}

const Input = {
  baseStyle: {
    borderColor: 'gray.25',
    field: {
      _placeholder: { color: 'gray.25' },
    },
  },
}

// needed for modal to not be cropped on ios browsers
const Modal = {
  baseStyle: {
    dialogContainer: {
      '@supports(height: -webkit-fill-available)': {},
      height: '100%',
    },
  },
}

const breakpoints = {
  '3xs': '20em', // 320px
  '2xs': '22.5em', // 360px
  xs: '25em', // 400 px
  sm: '30em', // 480 px
  smmd: '32.5em', // 520 px
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
};


const blackButton = defineStyle({
  variant: 'solid',
  background: 'black',
  color: 'white',
  fontFamily: 'Nunito Sans, Helvetica, serif',
  fontSize: { base: 'md', md: 'xl' },
  _hover: { color: 'black', bg: 'transparent' },
  borderRadius: '8px',
  borderWidth: 2,
  borderColor: 'black',
  fontWeight: 'bold',

  // let's also provide dark mode alternatives
  _dark: {
    background: 'white',
    color: 'black',
    _hover: { color: 'white', bg: 'transparent' },
  }
});

export const buttonTheme = defineStyleConfig({
  variants: { blackButton }
});

export const theme = extendTheme({
  styles: { global },
  fonts: {
    heading: 'Nunito Sans',
    body: 'Nunito Sans',
    mono: 'Nunito Sans',
  },
  colors: {
    blue: {
      97: '#F3F7FE',
    },
    gray: {
      25: '#DFE1DF',
      40: '#5B6670',
      50: '#DFE1DF33',
      57: '#8C9296',
    },
    yellow: {
      200: '#fff68D',
    },
    teal: {
      100: '#B9E1D6',
    },
    red: {
      100: '#FCD2BF',
      200: '#ED4337',
    },
  },
  fontSizes: {
    xxs: '0.625em',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  components: {
    AccordionItem,
    Modal,
    Text,
    TextArea,
    Input,
    Button: buttonTheme,
    Link: buttonTheme,
  },
  breakpoints,
});

export default theme;
